import React from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyle, theme } from "../styles";
import Nav from "./nav";
import Hero from "./hero";
import About from "./about";
import Work from "./work";
import Quote from "./quote";
import Event from "./events";
import Footer from "./footer";

const layout = () => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Nav />
        <Hero />
        <About />
        <Work />
        <Quote />
        <Event />
        <Footer />
      </ThemeProvider>
    </div>
  );
};

export default layout;
