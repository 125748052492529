import { createGlobalStyle } from "styled-components";
// import fonts from './fonts';
import variables from "./variables";
import theme from "./theme";
// import TransitionStyles from './TransitionStyles';
// import PrismStyles from './PrismStyles';

const GlobalStyle = createGlobalStyle`
  ${variables};

  /*========== Button Dark/Light ==========*/
.nav__btns {
  display: flex;
  align-items: center;
}

.change-theme {
  font-size: 1.25rem;
  color: var(--title-color);
  margin-right: var(--mb-1);
  cursor: pointer;
}

.change-theme:hover {
  color: var(--first-color);
}

  /*==================== BASE ====================*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0 0 var(--header-height) 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
}

h1, h2, h3, h4 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

/*==================== REUSABLE CSS CLASSES ====================*/
.section {
  padding: 2rem 0 4rem;
  min-height: 60vh;

}

.section__title {
  font-size: var(--h1-font-size);
  color: var(--title-color);
}

.section__subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-3);
}

.section__title, .section__subtitle {
  text-align: center;
}

/*==================== LAYOUT ====================*/
.container {
  max-width: 768px;
  margin-left: var(--mb-1-5);
  margin-right: var(--mb-1-5);
}

.grid {
  display: grid;
  gap: 1.5rem;
}

.header {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
}

/*========== BUTTONS ==========*/
.button {
  display: inline-block;
  background-color: var(--first-color);
  color: #FFF;
  padding: 1rem;
  border-radius: .5rem;
  font-weight: var(--font-medium);
}

.button:hover {
  background-color: var(--first-color-alt);
}

.button__icon {
  font-size: 1.25rem;
  margin-left: var(--mb-0-5);
  transition: .3s;
}

.button--white {
  background-color: #FFF;
  color: var(--first-color);
}

.button--white:hover {
  background-color: #FFF;
}

.button--flex {
  display: inline-flex;
  align-items: center;
}

.button--small {
  padding: .75rem 1rem;
}

.button--link {
  padding: 0;
  background-color: transparent;
  color: var(--first-color);
}

.button--link:hover {
  background-color: transparent;
  color: var(--first-color-alt);
}

/*==================== MEDIA QUERIES ====================*/
/* For small devices */
@media screen and (${theme.bp.small}) {
  .container {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }
}

/* For medium devices */
@media screen and (${theme.bp.medium}) {
  .about__container,
  .skills__container,
  .portfolio__content,
  .project__container,
  .contact__container,
  .footer__container {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* For medium devices 2 */
@media screen and (${theme.bp.medium2}) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }
  body {
    margin: 0;
  }
  .section {
    padding: 6rem 0 2rem;
  }
  .section__subtitle {
    margin-bottom: 4rem;
  }
  .header {
    top: 0;
    bottom: initial;
  }
  .header,
  .main,
  .footer__container {
    padding: 0 1rem;
  }
}

/* For large devices */
@media screen and (${theme.bp.large}) {
  .header,
  .main,
  .footer__container {
    padding: 0;
  }
}

`;

export default GlobalStyle;
