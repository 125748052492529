import React, { useEffect, useRef } from "react";
import { srConfig } from "../config";
import sr from "../utils/sr";
import styled from "styled-components";
import quoteMark from "../image/quoteWhite.png";

const StyledQuote = styled.section`
  text-align: center;

  .project__bg {
    background-color: var(--first-color-second);
    padding-top: 3rem;
  }

  .project__title {
    font-size: var(--h2-font-size);
    margin-bottom: var(--mb-0-75);
  }

  .project__description {
    margin-bottom: var(--mb-1-5);
  }

  .project__title,
  .project__description {
    color: #fff;
  }

  .project__img {
    width: 232px;
    justify-self: center;
  }

  @media screen and (${(props) => props.theme.bp.small}) {
    .project__img {
      width: 200px;
    }
  }

  @media screen and (${(props) => props.theme.bp.medium2}) {
    .project {
      text-align: initial;
    }
    .project__bg {
      background: none;
    }
    .project__container {
      background-color: var(--first-color-second);
      border-radius: 1rem;
      padding: 3rem 2.5rem 0;
      grid-template-columns: 1fr max-content;
      column-gap: 3rem;
    }
    .project__data {
      padding-top: 0.8rem;
    }
  }
`;

const Quote = () => {
  const revealContainer = useRef(null);

  useEffect(() => {
    sr.reveal(revealContainer.current, srConfig());
  }, []);
  return (
    <StyledQuote className="project section" id="quote" ref={revealContainer}>
      <div className="project__bg">
        <div className="project__container container grid">
          <div className="project__data">
            <h2 className="project__title">Quote keep me moving</h2>
            <p className="project__description">
              "What Got You Here Won't Get You There"
            </p>
            {/* <a
              href="#"
              rel="noopener noreferrer"
              className="button button--flex button--white"
            >
              Contact Me
              <i className="uil uil-message button__icon"></i>
            </a> */}
          </div>

          <img src={quoteMark} alt="" className="project__img" />
        </div>
      </div>
    </StyledQuote>
  );
};

export default Quote;
