// import mixins from './mixins';

const theme = {
  bp1: {
    mobileS: `max-width: 330px`,
    mobileM: `max-width: 400px`,
    mobileL: `max-width: 480px`,
    tabletS: `max-width: 600px`,
    tabletL: `max-width: 768px`,
    desktopXS: `max-width: 900px`,
    desktopS: `max-width: 1080px`,
    desktopM: `max-width: 1200px`,
    desktopL: `max-width: 1400px`,
  },
  bp: {
    small: `max-width: 350px`,
    medium: `min-width: 568px`,
    medium2: `min-width: 768px`,
    large: `min-width: 1024px`,
  },

  // mixins,
};

export default theme;
