import { css } from "styled-components";

const variables = css`
  :root {
    --header-height: 3rem;
    /*========== Colors ==========*/
    /* Change favorite color */
    --hue-color: 250;
    /*Purple 250 - Green 142 - Blue 230 - Pink 340*/
    /* HSL color mode */
    --first-color: hsl(var(--hue-color), 69%, 61%);
    --first-color-second: hsl(var(--hue-color), 69%, 61%);
    --first-color-alt: hsl(var(--hue-color), 57%, 53%);
    --first-color-lighter: hsl(var(--hue-color), 92%, 85%);
    --title-color: hsl(var(--hue-color), 8%, 15%);
    --text-color: hsl(var(--hue-color), 8%, 45%);
    --text-color-light: hsl(var(--hue-color), 8%, 65%);
    --input-color: hsl(var(--hue-color), 70%, 96%);
    --body-color: hsl(var(--hue-color), 60%, 99%);
    --container-color: #fff;
    --scroll-bar-color: hsl(var(--hue-color), 12%, 90%);
    --scroll-thumb-color: hsl(var(--hue-color), 12%, 80%);
    /*========== Font and typography ==========*/
    --body-font: "Poppins", sans-serif;
    --big-font-size: 2rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1.125rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
    /*========== Font weight ==========*/
    --font-medium: 500;
    --font-semi-bold: 600;
    /*========== Margenes Bottom ==========*/
    --mb-0-25: 0.25rem;
    --mb-0-5: 0.5rem;
    --mb-0-75: 0.75rem;
    --mb-1: 1rem;
    --mb-1-5: 1.5rem;
    --mb-2: 2rem;
    --mb-2-5: 2.5rem;
    --mb-3: 3rem;
    /*========== z index ==========*/
    --z-tooltip: 10;
    --z-fixed: 100;
    --z-modal: 1000;

    /*========== Variables Dark theme ==========*/
    body.dark-theme {
      /* HSL color mode */
      --first-color-second: hsl(var(--hue-color), 30%, 8%);
      --title-color: hsl(var(--hue-color), 8%, 95%);
      --text-color: hsl(var(--hue-color), 8%, 75%);
      --input-color: hsl(var(--hue-color), 29%, 16%);
      --body-color: hsl(var(--hue-color), 28%, 12%);
      --container-color: hsl(var(--hue-color), 29%, 16%);
      --scroll-bar-color: hsl(var(--hue-color), 12%, 48%);
      --scroll-thumb-color: hsl(var(--hue-color), 12%, 36%);
    }

    /*========== Work ==========*/
    --tab-height: 42px;
    --tab-width: 180px;
    --easing: cubic-bezier(0.645, 0.045, 0.355, 1);
    --transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    --slate: #8892b0;
    --lightest-navy: #233554;
    --green: #64ffda;
  }

  @media screen and (min-width: 968px) {
    :root {
      --big-font-size: 3rem;
      --h1-font-size: 2.25rem;
      --h2-font-size: 1.5rem;
      --h3-font-size: 1.25rem;
      --normal-font-size: 1rem;
      --small-font-size: 0.875rem;
      --smaller-font-size: 0.813rem;
    }
  }
`;

export default variables;
