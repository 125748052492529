import React, { useEffect, useRef } from "react";
import { srConfig } from "../config";
import sr from "../utils/sr";
import banner from "../image/banner-image.png";
import styled from "styled-components";
import { FiGithub } from "react-icons/fi";
import { IoMailOutline } from "react-icons/io5";
import { AiOutlineLinkedin, AiOutlineMedium } from "react-icons/ai";
import { FaGoodreadsG } from "react-icons/fa";
import { RiMouseLine } from "react-icons/ri";
import { BiDownArrowAlt } from "react-icons/bi";

const StyledHero = styled.section`
  .home__container {
    gap: 1rem;
  }

  .home__content {
    grid-template-columns: 0.5fr 3fr;
    padding-top: 3.5rem;
    align-items: center;
  }

  .home__social {
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;
  }

  .home__social-icon {
    font-size: 1.25rem;
    color: var(--first-color);
  }

  .home__social-icon:hover {
    color: var(--first-color-alt);
  }

  .home__blob {
    width: 200px;
    fill: var(--first-color);
  }

  .home__blob-img {
    width: 170px;
  }

  .home__data {
    grid-column: 1/3;
  }

  .home__title {
    font-size: var(--big-font-size);
  }

  .home__subtitle {
    /* font-size: var(--h3-font-size); */
    font-size: var(--h3-font-size);
    color: var(--text-color);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-0-75);
    margin-right: 0;
    display: inline-block;
  }

  .home__description {
    margin-bottom: var(--mb-2);
  }

  .home__scroll {
    display: none;
  }

  .home__scroll-button {
    color: var(--first-color);
    transition: 0.3s;
  }

  .home__scroll-button:hover {
    transform: translateY(0.25rem);
  }

  .home__scroll-mouse {
    font-size: 2rem;
  }

  .home__scroll-name {
    font-size: var(--small-font-size);
    color: var(--title-color);
    font-weight: var(--font-medium);
    margin-right: var(--mb-0-25);
  }

  .home__scroll-arrow {
    font-size: 1.25rem;
  }

  .hidden {
    opacity: 0;
  }
  .console-container {
    block-size: 2.5rem;
  }
  .console-underscore {
    display: inline-block;
    position: relative;
    top: -0.14em;
    left: 0;
  }

  @media screen and (${(props) => props.theme.bp.small}) {
    .home__content {
      grid-template-columns: 0.25fr 3fr;
    }
    .home__blob {
      width: 180px;
    }
  }

  @media screen and (${(props) => props.theme.bp.medium}) {
    .home__content {
      grid-template-columns: max-content 1fr 1fr;
    }
    .home__data {
      grid-column: initial;
    }
    .home__img {
      order: 1;
      justify-self: center;
    }
  }

  @media screen and (${(props) => props.theme.bp.medium2}) {
    .home__container {
      row-gap: 5rem;
    }
    .home__content {
      padding-top: 5.5rem;
      column-gap: 2rem;
    }
    .home__blob {
      width: 270px;
    }
    .home__scroll {
      display: block;
    }
    .home__scroll-button {
      margin-left: 3rem;
    }
  }

  @media screen and (${(props) => props.theme.bp.large}) {
    .home__blob {
      width: 320px;
    }
    .home__social {
      transform: translateX(-6rem);
    }
  }
`;

const Hero = () => {
  const revealContainer = useRef(null);

  useEffect(() => {
    sr.reveal(revealContainer.current, srConfig());
    // function([string1, string2],target id,[color1,color2])
    consoleText(
      ["Full Stack Developer", "Consultant", "Product Manager"],
      "text",
      ["tomato", "rebeccapurple", "lightblue"]
    );
  }, []);

  const consoleText = (words, id, colors) => {
    if (colors === undefined) colors = ["#fff"];
    let visible = true;
    let con = document.getElementById("console");
    let letterCount = 1;
    let x = 1;
    let waiting = false;
    let target = document.getElementById(id);
    target.setAttribute("style", "color:" + colors[0]);
    window.setInterval(function () {
      if (letterCount === 0 && waiting === false) {
        waiting = true;
        target.innerHTML = words[0].substring(0, letterCount);
        window.setTimeout(function () {
          let usedColor = colors.shift();
          colors.push(usedColor);
          let usedWord = words.shift();
          words.push(usedWord);
          x = 1;
          target.setAttribute("style", "color:" + colors[0]);
          letterCount += x;
          waiting = false;
        }, 1000);
      } else if (letterCount === words[0].length + 1 && waiting === false) {
        waiting = true;
        window.setTimeout(function () {
          x = -1;
          letterCount += x;
          waiting = false;
        }, 1000);
      } else if (waiting === false) {
        target.innerHTML = words[0].substring(0, letterCount);
        letterCount += x;
      }
    }, 120);
    window.setInterval(function () {
      if (visible === true) {
        con.className = "console-underscore hidden";
        visible = false;
      } else {
        con.className = "console-underscore";

        visible = true;
      }
    }, 400);
  };
  return (
    <StyledHero className="home section" id="home" ref={revealContainer}>
      <div className="home__container container grid">
        <div className="home__content grid">
          <div className="home__social">
            <a
              href="https://www.linkedin.com/in/teowangwei/"
              target="_blank"
              rel="noopener noreferrer"
              className="home__social-icon"
            >
              <AiOutlineLinkedin />
            </a>

            <a
              href="https://medium.com/@onewayteo"
              target="_blank"
              rel="noopener noreferrer"
              className="home__social-icon"
            >
              <AiOutlineMedium />
            </a>

            <a
              href="https://github.com/Onewayteo"
              target="_blank"
              rel="noopener noreferrer"
              className="home__social-icon"
            >
              <FiGithub />
            </a>
            <a
              href="https://goodreads.com/onewayteo"
              target="_blank"
              rel="noopener noreferrer"
              className="home__social-icon"
            >
              <FaGoodreadsG />
            </a>
          </div>

          <div className="home__img">
            <img className="home__blob-img" src={banner} alt="alt" />
          </div>

          <div className="home__data">
            <h1 className="home__title">Hi, I'm OneWay</h1>
            {/* <h3 className="home__subtitle">Full Stack Developer</h3> */}
            <div className="console-container">
              <h3 className="home__subtitle" id="text"></h3>
              <div className="console-underscore" id="console">
                &#95;
              </div>
            </div>
            <p className="home__description">
              A Singapore-based software engineer who enjoys developing
              applications and helping people grow together.
            </p>
            <a
              href="mailto:onewayteo@gmail.com"
              className="button button--flex"
            >
              Contact Me <IoMailOutline className="button__icon" />
            </a>
          </div>
        </div>

        <div className="home__scroll">
          <a href="#about" className="home__scroll-button button--flex">
            {/* <i className="uil uil-mouse-alt home__scroll-mouse"></i> */}
            <RiMouseLine className="home__scroll-mouse" />
            <span className="home__scroll-name">Scroll down</span>
            <i className="uil uil-arrow-down home__scroll-arrow"></i>
            <BiDownArrowAlt className="home__scroll-arrow" />
          </a>
        </div>
      </div>
    </StyledHero>
  );
};

export default Hero;
