import React, { useEffect, useRef } from "react";
import { srConfig } from "../config";
import sr from "../utils/sr";
import profile from "../image/profile.jpg";
import styled from "styled-components";

const StyledAbout = styled.section`
  .about__img {
    width: 200px;
    border-radius: 0.5rem;
    justify-self: center;
    align-self: center;
  }

  .about__description {
    text-align: center;
    margin-bottom: var(--mb-2-5);
  }

  .about__info {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: var(--mb-2-5);
  }

  .about__info-title {
    font-size: var(--h2-font-size);
    font-weight: var(--font-semi-bold);
    color: var(--title-color);
  }

  .about__info-name {
    font-size: var(--smaller-font-size);
  }

  .about__info-title,
  .about__info-name {
    display: block;
    text-align: center;
  }

  .about__buttons {
    display: flex;
    justify-content: center;
  }
  @media screen and (${(props) => props.theme.bp.medium2}) {
    .about__container {
      column-gap: 5rem;
    }
    .about__img {
      width: 350px;
    }
    .about__description {
      text-align: initial;
    }
    .about__info {
      justify-content: space-between;
    }
    .about__buttons {
      justify-content: initial;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);

  useEffect(() => {
    sr.reveal(revealContainer.current, srConfig());
  }, []);
  return (
    <StyledAbout className="about section" id="about" ref={revealContainer}>
      <h2 className="section__title">About Me</h2>
      <span className="section__subtitle">My introduction</span>

      <div className="about__container container grid">
        <img src={profile} alt="" className="about__img" />

        <div className="about__data">
          <p className="about__description">
            Hello! This is OneWay and I enjoy developing full-stack applications
            that help to solve real life problems. I am self-motivated and an
            avid technology enthusiast.
            <br />
            <br />
            Motivated by the experience of securing multiple offers after
            self-learning, I have helped 6 friends and acquaintances land
            technology/developer roles with/without an engineering degree.
            <br />
            <br />
            Technologies that I am working with recently: MERN (JavaScript) |
            Java | AWS
          </p>

          <div className="about__info">
            <div>
              <span className="about__info-title">3+</span>
              <span className="about__info-name">
                Years <br /> experience
              </span>
            </div>

            <div>
              <span className="about__info-title">10+</span>
              <span className="about__info-name">
                Completed <br /> projects
              </span>
            </div>

            <div>
              <span className="about__info-title">2+</span>
              <span className="about__info-name">
                Companies <br /> worked
              </span>
            </div>
          </div>

          {/* <div className="about__buttons">
            <a
              download=""
              href="assets/pdf/Alexa-Cv.pdf"
              className="button button--flex"
            >
              Download CV <i className="uil uil-download-alt button__icon"></i>
            </a>
          </div> */}
        </div>
      </div>
    </StyledAbout>
  );
};

export default About;
