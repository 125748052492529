import React, { useEffect } from "react";
import "./App.css";
import Layout from "./components/layout";
import ReactGA from "react-ga";

const App = () => {
  useEffect(() => {
    const trackingId = "UA-207945995-2"; // Replace with your Google Analytics tracking ID
    ReactGA.initialize(trackingId);
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <div className="App">
      <Layout></Layout>
    </div>
  );
};

export default App;
