import React from "react";
import styled from "styled-components";

const StyledFooter = styled.footer`
  .footer {
    padding-top: 2rem;
  }

  .footer__container {
    row-gap: 3.5rem;
  }

  .footer__bg {
    background-color: var(--first-color-second);
    padding: 2rem 0 3rem;
  }

  .footer__title {
    font-size: var(--h1-font-size);
    margin-bottom: var(--mb-0-25);
  }

  .footer__subtitle {
    font-size: var(--small-font-size);
  }

  .footer__links {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }

  .footer__link:hover {
    color: var(--first-color-lighter);
  }

  .footer__social {
    font-size: 1.25rem;
    margin-right: var(--mb-1-5);
  }

  .footer__social:hover {
    color: var(--first-color-lighter);
  }

  .footer__copy {
    font-size: var(--smaller-font-size);
    text-align: center;
    color: var(--text-color-light);
    margin-top: var(--mb-3);
  }

  .footer__title,
  .footer__subtitle,
  .footer__link,
  .footer__social {
    color: #fff;
  }

  @media screen and (${(props) => props.theme.bp.medium2}) {
    .footer__container {
      grid-template-columns: repeat(3, 1fr);
    }
    .footer__bg {
      padding: 3rem 0 3.5rem;
    }
    .footer__links {
      flex-direction: row;
      column-gap: 2rem;
    }
    .footer__socials {
      justify-self: flex-end;
    }
    .footer__copy {
      margin-top: 4.5rem;
    }
  }
`;

const footer = () => {
  return (
    <StyledFooter className="footer" id="contact">
      <div className="footer__bg">
        <div className="footer__container container grid">
          <div>
            <h1 className="footer__title">OneWay Teo</h1>
            <span className="footer__subtitle">Web Developer</span>
          </div>

          {/* <ul className="footer__links">
            <li>
              <a href="#services" className="footer__link">
                Services
              </a>
            </li>
            <li>
              <a href="#portfolio" className="footer__link">
                Portfolio
              </a>
            </li>
            <li>
              <a href="#contact" className="footer__link">
                Contactme
              </a>
            </li>
          </ul> */}

          {/* <div className="footer__socials">
            <a
              href="https://www.facebook.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__social"
            >
              <i className="uil uil-facebook-f"></i>
            </a>
            <a
              href="https://www.instagram.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__social"
            >
              <i className="uil uil-instagram"></i>
            </a>
            <a
              href="https://twitter.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__social"
            >
              <i className="uil uil-twitter-alt"></i>
            </a>
          </div> */}
        </div>

        <p className="footer__copy">&#169; Built by OneWay Teo</p>
      </div>
    </StyledFooter>
  );
};

export default footer;
