import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  AiOutlineAppstore,
  AiOutlineHome,
  AiOutlineUser,
} from "react-icons/ai";
import { BiCalendarEvent } from "react-icons/bi";
import { BsChatSquareQuote } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { IoMailOutline } from "react-icons/io5";
import { FaLaptopCode, FaMoon, FaSun } from "react-icons/fa";

const StyledHeader = styled.header`
  width: 100%;
  position: fixed;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);

  box-shadow: ${(props) =>
    props.scrolled ? `0 -1px 4px rgba(0, 0, 0, 0.15)` : `0`};

  /*==================== NAV ====================*/
  .nav {
    max-width: 968px;
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav__logo,
  .nav__toggle {
    color: var(--title-color);
    font-weight: var(--font-medium);
  }

  .nav__logo:hover {
    color: var(--first-color);
  }

  .nav__toggle {
    font-size: 1.1rem;
    cursor: pointer;
    margin-top: 0.3rem;
  }

  .nav__toggle:hover {
    color: var(--first-color);
  }

  @media screen and (max-width: 767px) {
    .nav__menu {
      position: fixed;
      bottom: ${(props) => (props.menuOpen ? `0` : `-100%`)};
      left: 0;
      width: 100%;
      background-color: var(--body-color);
      padding: 2rem 1.5rem 4rem;
      box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
      border-radius: 1.5rem 1.5rem 0 0;
      transition: 0.3s;
    }
  }

  .nav__list {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  .nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--small-font-size);
    color: var(--title-color);
    font-weight: var(--font-medium);
  }

  .nav__link:hover {
    color: var(--first-color);
  }

  .nav__icon {
    font-size: 1.2rem;
  }

  .nav__close {
    position: absolute;
    right: 1.3rem;
    bottom: 0.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--first-color);
  }

  .nav__close:hover {
    color: var(--first-color-alt);
  }

  /* Active link */
  .active-link {
    color: var(--first-color);
  }

  /* Change background header */
  .scroll-header {
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
  }

  .checkbox {
    align-items: center;
    justify-content: center;
    opacity: 0;
    position: absolute;
  }

  .label {
    background-color: #111;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    height: 26px;
    width: 50px;
    transform: scale(0.7);
  }

  .label .ball {
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    height: 22px;
    width: 22px;
    transform: translateX(0px);
    transition: transform 0.2s linear;
  }

  .checkbox:checked + .label .ball {
    transform: translateX(24px);
  }

  .fa-moon {
    color: #f1c40f;
  }

  .fa-sun {
    color: #f39c12;
  }

  @media screen and (${(props) => props.theme.bp.small}) {
    .nav__menu {
      padding: 2rem 0.25rem 4rem;
    }
    .nav__list {
      column-gap: 0;
    }
  }

  @media screen and (${(props) => props.theme.bp.medium2}) {
    .header {
      top: 0;
      bottom: initial;
    }
    .nav {
      height: calc(var(--header-height) + 1.5rem);
      column-gap: 1rem;
    }
    .nav__icon,
    .nav__close,
    .nav__toggle {
      display: none;
    }
    .nav__list {
      display: flex;
      column-gap: 2rem;
    }
    .nav__menu {
      margin-left: auto;
    }
  }
`;

const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setscrolled] = useState(false);

  // Dark Theme Configuration
  const [checkDarkTheme, setCheckDarkTheme] = useState(
    localStorage.getItem("selected-theme") === "dark" ? true : false
  );

  if (checkDarkTheme) {
    localStorage.setItem("selected-theme", "dark");
    document.body.classList.add("dark-theme");
  } else {
    localStorage.setItem("selected-theme", "light");
    document.body.classList.remove("dark-theme");
  }

  const navLinks = [
    {
      name: "Home",
      url: "/#home",
      icon: <AiOutlineHome />,
    },
    {
      name: "About",
      url: "/#about",
      icon: <AiOutlineUser />,
    },
    {
      name: "Work",
      url: "/#work",
      icon: <FaLaptopCode />,
    },
    {
      name: "Quote",
      url: "/#quote",
      icon: <BsChatSquareQuote />,
    },
    {
      name: "Events",
      url: "/#events",
      icon: <BiCalendarEvent />,
    },
    // {
    //   name: "Contact",
    //   url: "/#contact",
    //   icon: <IoMailOutline />,
    // },
  ];

  useEffect(() => {
    const sections = document.querySelectorAll("section[id]");

    const scrollActive = () => {
      const scrollY = window.pageYOffset;
      sections.forEach((current) => {
        const sectionHeight = current.offsetHeight;
        const sectionTop = current.offsetTop - 50;
        const sectionId = current.getAttribute("id");

        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          document
            .querySelector(".nav__menu a[href*=" + sectionId + "]")
            .classList.add("active-link");
        } else {
          document
            .querySelector(".nav__menu a[href*=" + sectionId + "]")
            .classList.remove("active-link");
        }
      });
    };
    const scrollHeader = () => {
      const scrollY = window.pageYOffset;

      const nav = document.getElementById("header");
      if (scrollY >= 80) setscrolled(true);
      else setscrolled(false);
    };
    setTimeout(() => {
      window.addEventListener("scroll", scrollActive);
      window.addEventListener("scroll", scrollHeader);
    }, 100);
  });

  return (
    <StyledHeader
      className="header"
      id="header"
      menuOpen={menuOpen}
      scrolled={scrolled}
    >
      <nav className="nav container">
        <a href="" rel="noopener noreferrer" className="nav__logo">
          OneWay
        </a>

        <div className="nav__menu" id="nav-menu">
          <ul className="nav__list grid">
            {navLinks &&
              navLinks.map(({ url, name, icon }, i) => (
                <li key={i} className="nav__item">
                  <a
                    href={url}
                    rel="noopener noreferrer"
                    className={`nav__link ${i === 0 ? "active-link" : ""}`}
                    onClick={() => setMenuOpen(false)}
                  >
                    <div className="nav__icon">{icon}</div>
                    {name}
                  </a>
                </li>
              ))}
          </ul>

          <IoMdClose
            className="nav__close"
            onClick={() => setMenuOpen(false)}
          />
        </div>

        <div className="nav__btns">
          <div>
            <input
              type="checkbox"
              className="checkbox"
              id="chk"
              checked={checkDarkTheme}
              onChange={(event) => setCheckDarkTheme(event.target.checked)}
            />
            <label className="label" htmlFor="chk">
              <FaMoon className="fas fa-moon" />
              <FaSun className="fas fa-sun" />
              <div className="ball"></div>
            </label>
          </div>
          {/* <i className="uil uil-moon change-theme" id="theme-button"></i> */}

          <div className="nav__toggle" onClick={() => setMenuOpen(true)}>
            <AiOutlineAppstore />
          </div>
        </div>
      </nav>
    </StyledHeader>
  );
};

export default Nav;
