import React, { useEffect, useRef } from "react";
import { srConfig } from "../config";
import sr from "../utils/sr";
import styled from "styled-components";
import workshop from "../image/workshop.jpg";
import consultant from "../image/consultant.jpeg";
import hackathon from "../image/hackathon.png";
import gsZoom from "../image/gs_zoom.jpeg";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from "swiper";

// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

const StyledEvents = styled.section`
  /* This padding is different from other section in GlobalStyle */
  padding: 2rem 0 4rem !important;
  width: 100%;
  .portfolio__container {
    overflow: initial;
  }

  .portfolio__content {
    padding: 0 1.5rem;
  }

  .portfolio__img {
    width: 265px;
    border-radius: 0.5rem;
    justify-self: center;
  }

  .portfolio__title {
    font-size: var(--h3-font-size);
    margin-bottom: var(--mb-0-5);
  }

  .portfolio__description {
    margin-bottom: var(--mb-0-75);
  }

  .portfolio__button:hover .button__icon {
    transform: translateX(0.25rem);
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    content: "";
  }

  .swiper-portfolio-icon {
    font-size: 2rem;
    color: var(--first-color);
  }

  .swiper-button-prev {
    left: -0.5rem;
  }

  .swiper-button-next {
    right: -0.5rem;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: -2.5rem;
  }

  .swiper-pagination-bullet-active {
    background-color: var(--first-color);
  }

  .swiper-button-prev,
  .swiper-button-next,
  .swiper-pagination-bullet {
    outline: none;
  }

  @media screen and (${(props) => props.theme.bp.medium2}) {
    .portfolio__img {
      width: 320px;
    }
    .portfolio__content {
      align-items: center;
    }
  }

  @media screen and (${(props) => props.theme.bp.large}) {
    .swiper-portfolio-icon {
      font-size: 3.5rem;
    }
    .swiper-button-prev {
      left: -3.5rem;
    }
    .swiper-button-next {
      right: -3.5rem;
    }
  }
`;

const Events = () => {
  const revealContainer = useRef(null);

  useEffect(() => {
    sr.reveal(revealContainer.current, srConfig());
  }, []);
  return (
    <StyledEvents
      className="portfolio section"
      id="events"
      ref={revealContainer}
    >
      <h2 className="section__title">Events & Appearances</h2>
      <span className="section__subtitle">Most recent work</span>

      <Swiper
        className="portfolio__container container swiper-container mySwiper"
        cssMode={true}
        loop={true}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        pagination={{
          el: ".swiper-pagination",
          clickable: true,
        }}
      >
        <SwiperSlide className="portfolio__content grid ">
          <img src={workshop} alt="" className="portfolio__img" />

          <div className="portfolio__data">
            <h3 className="portfolio__title">
              Web Development Workshop Organiser & Speaker
            </h3>
            <p className="portfolio__description">
              Taken the initiative to approach/collaborate with high school
              committees and collaborated with 4 friends to host a two-day web
              development workshop for 33 high school students to promote
              technology in 2019. This workshop had gained newspaper
              recognition.
            </p>
            {/* <a
              href="#about"
              rel="noopener noreferrer"
              className="button button--flex button--small portfolio__button"
            >
              Demo
              <i className="uil uil-arrow-right button__icon"></i>
            </a> */}
          </div>
        </SwiperSlide>

        <SwiperSlide className="portfolio__content grid ">
          <img src={consultant} alt="" className="portfolio__img" />

          <div className="portfolio__data">
            <h3 className="portfolio__title">Consultant</h3>
            <p className="portfolio__description">
              Motivated by the experience of securing multiple offers after
              self-learning, I have helped 6 friends and acquaintances land
              technology/developer roles with/without an engineering degree.
            </p>
            {/* <a
              href="#about"
              rel="noopener noreferrer"
              className="button button--flex button--small portfolio__button"
            >
              Demo
              <i className="uil uil-arrow-right button__icon"></i>
            </a> */}
          </div>
        </SwiperSlide>

        <SwiperSlide className="portfolio__content grid ">
          <img src={hackathon} alt="" className="portfolio__img" />

          <div className="portfolio__data">
            <h3 className="portfolio__title">
              Global Hackathon: DBS Paradigm Shift (Full-Stack Web Developer)
            </h3>
            <p className="portfolio__description">
              Developed a MERN(MongoDB, ExpressJS, ReactJS, NodeJS) stack
              website from scratch on AWS with Docker, part-time in one month
              and got to semi-final round with a team of 4.
            </p>
            {/* <a
              href="#about"
              rel="noopener noreferrer"
              className="button button--flex button--small portfolio__button"
            >
              Demo
              <i className="uil uil-arrow-right button__icon"></i>
            </a> */}
          </div>
        </SwiperSlide>
        <SwiperSlide className="portfolio__content grid ">
          <img src={gsZoom} alt="" className="portfolio__img" />

          <div className="portfolio__data">
            <h3 className="portfolio__title">
              Guest Speaker for Goldman Sachs Campus Recruitment
            </h3>
            <p className="portfolio__description">
              Honoured to be one of the guest speakers sharing my engineering
              career at Goldman Sachs with about 130 university students over
              Zoom. We are always looking for valuable talent!
            </p>
            {/* <a
              href="#about"
              rel="noopener noreferrer"
              className="button button--flex button--small portfolio__button"
            >
              Demo
              <i className="uil uil-arrow-right button__icon"></i>
            </a> */}
          </div>
        </SwiperSlide>

        <div className="swiper-button-next">
          <HiChevronRight className="swiper-portfolio-icon" />
        </div>
        <div className="swiper-button-prev">
          <HiChevronLeft className="swiper-portfolio-icon" />
        </div>

        <div className="swiper-pagination"></div>
      </Swiper>
    </StyledEvents>
  );
};

export default Events;
